// @TODO add the bus icon
// Flip the bold highlights on phone

import React, { ReactElement } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl';

import { borderRadius, grey, spacing } from '../../styles';
import { Apartment } from '../../interfaces/common';
import FloorIcon from '../common/icons/FloorIcon';
import RentIcon from '../common/icons/RentIcon';
import SizeIcon from '../common/icons/SizeIcon';
// import busIcon from '../../images/icons/bus.svg';
import metroIcon from '../../images/icons/metro.svg';
import beachIcon from '../../images/icons/beach.svg';
import shoppingMallIcon from '../../images/icons/shopping-mall.svg';
import carIcon from '../../images/icons/car.svg';
import cityIcon from '../../images/icons/city.svg';
import diamondIcon from '../../images/icons/diamond.svg';
import dishwasherIcon from '../../images/icons/dishwasher.svg';
import forestIcon from '../../images/icons/forest.svg';
import guranteeIcon from '../../images/icons/guarantee.svg';
import shopIcon from '../../images/icons/shop.svg';
import washingMachineIcon from '../../images/icons/washing-machine.svg';
import waterIcon from '../../images/icons/water.svg';
import Button from '../common/Button';
import Divider from '../common/Divider';
import Icon from '../common/Icon';
import AvailableFromIcon from '../common/icons/AvailableFromIcon';

interface Props {
  apartment: Apartment;
}

const Container = styled.div``;

const MainHighlights = styled.div`
  background-color: ${grey['1']};
  padding: ${spacing['6']} ${spacing['5']};
  max-width: 100%;
  border-radius: ${borderRadius.xl};
`;

const RowContainer = styled.div`
  padding: ${spacing['3']} 0;
`;

const ApartmentHighlightsCard = ({ apartment }: Props): ReactElement => {
  const intl = useIntl();

  return (
    <Container>
      <MainHighlights>
        <RowContainer>
          <Row center="xs" middle="xs">
            <Col xs={12} md={6}>
              <SizeIcon size={apartment.size} isLarge={true} />
            </Col>
            <Col xs={12} md={6}>
              <FloorIcon floor={apartment.floor} isLarge={true} />
            </Col>
          </Row>
        </RowContainer>
        <Divider />
        <RowContainer>
          <Row center="xs" middle="xs">
            <Col xs={12} md={6}>
              <Icon
                icon={diamondIcon}
                text={intl.formatMessage({ id: 'apartment.condition' })}
                isLarge={true}
              />
              {apartment.highlights.hasParking && (
                <Icon
                  icon={carIcon}
                  text={intl.formatMessage({ id: 'apartment.parkingOption' })}
                  isLarge={true}
                />
              )}
            </Col>
            <Col xs={12} md={6}>
              {apartment.highlights.hasDishwasher && (
                <Icon
                  icon={dishwasherIcon}
                  text={intl.formatMessage({ id: 'apartment.dishwasher' })}
                  isLarge={true}
                />
              )}
              {apartment.highlights.hasWashingMachineConnection && (
                <Icon
                  icon={washingMachineIcon}
                  text={intl.formatMessage({
                    id: 'apartment.washingMachineConnection',
                  })}
                  isLarge={true}
                />
              )}
            </Col>
          </Row>
        </RowContainer>
        <Divider />

        <RowContainer>
          <Row center="xs" middle="xs">
            <Col xs={12} md={6}>
              {apartment.highlights.distanceFromMetro && (
                <Icon
                  icon={metroIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.distanceFromMetro',
                    },
                    { distance: apartment.highlights.distanceFromMetro },
                  )}
                  isLarge={true}
                />
              )}
              {apartment.highlights.minutesFromCenter && (
                <Icon
                  icon={cityIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.minutesFromCenter',
                    },
                    { minutes: apartment.highlights.minutesFromCenter },
                  )}
                  isLarge={true}
                />
              )}
              {apartment.highlights.distanceFromBeach && (
                <Icon
                  icon={beachIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.distanceFromBeach',
                    },
                    { distance: apartment.highlights.distanceFromBeach },
                  )}
                  isLarge={true}
                />
              )}
            </Col>
            <Col xs={12} md={6}>
              {apartment.highlights.distanceFromForest && (
                <Icon
                  icon={forestIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.distanceFromForest',
                    },
                    { distance: apartment.highlights.distanceFromForest },
                  )}
                  isLarge={true}
                />
              )}
              {apartment.highlights.distanceFromPark && (
                <Icon
                  icon={forestIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.distanceFromPark',
                    },
                    { distance: apartment.highlights.distanceFromPark },
                  )}
                  isLarge={true}
                />
              )}
              {apartment.highlights.distanceFromShop && (
                <Icon
                  icon={shopIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.distanceFromShop',
                    },
                    { distance: apartment.highlights.distanceFromShop },
                  )}
                  isLarge={true}
                />
              )}
              {apartment.highlights.distanceFromMall && (
                <Icon
                  icon={shoppingMallIcon}
                  text={intl.formatMessage(
                    {
                      id: 'apartment.distanceFromMall',
                    },
                    { distance: apartment.highlights.distanceFromMall },
                  )}
                  isLarge={true}
                />
              )}
            </Col>
          </Row>
        </RowContainer>
        <Divider />
        <RowContainer>
          <Row center="xs" middle="xs">
            <Col xs={12} md={6}>
              <Icon
                icon={guranteeIcon}
                text={intl.formatMessage(
                  {
                    id: 'apartment.deposit',
                  },
                  { deposit: apartment.rent * 2 },
                )}
                isLarge={true}
              />
              <RentIcon rent={apartment.rent} isLarge={true} />
            </Col>
            <Col xs={12} md={6}>
              <Icon
                icon={waterIcon}
                text={intl.formatMessage(
                  {
                    id: 'apartment.waterPerPerson',
                  },
                  { waterCost: apartment.waterPerPerson },
                )}
                isLarge={true}
              />
              <AvailableFromIcon
                availableFrom={apartment.availableFrom}
                isLarge={true}
              />
            </Col>
            <Link
              style={{ paddingTop: spacing['4'] }}
              to={`/${apartment.id}/vuokrahakemus`}
            >
              <Button>
                <FormattedMessage id="common.applyNow" />
              </Button>
            </Link>
          </Row>
        </RowContainer>
      </MainHighlights>
    </Container>
  );
};

export default ApartmentHighlightsCard;
