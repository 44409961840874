import { LatLng, Icon } from 'leaflet';
import React, { ReactElement } from 'react';
import {
  Map as ReactLeafletMap,
  TileLayer,
  Marker,
  Popup,
} from 'react-leaflet';
import { Apartment } from '../../interfaces/common';

import './Map.css';

const getSrc = ({
  username,
  styleID,
  token,
}: {
  username?: string;
  styleID?: string;
  token?: string;
}) =>
  `https://api.mapbox.com/styles/v1/${username}/${styleID}/tiles/256/{z}/{x}/{y}@2x?access_token=${token}`;

type Props = {
  location: Apartment['location'];
  popUpText: string;
};

const Map = ({ location, popUpText }: Props): ReactElement | null => {
  if (typeof window === 'undefined') {
    // Map can only be rendered on the client
    return null;
  }

  const markerPosition = new LatLng(location[0], location[1]);

  const customMarker = new Icon({
    iconUrl: require('../../images/icons/location.svg'),
    iconRetinaUrl: require('../../images/icons/location.svg'),
    iconSize: [45, 45],
    className: 'leaflet-div-icon',
  });

  return (
    <ReactLeafletMap center={location} zoom={17}>
      <TileLayer
        style={{
          height: '20rem',
        }}
        url={getSrc({
          username: process.env.GATSBY_MAPBOX_USERNAME,
          styleID: process.env.GATSBY_MAPBOX_STYLE_ID,
          token: process.env.GATSBY_MAPBOX_PUBLIC_TOKEN,
        })}
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
      ></TileLayer>
      <Marker position={markerPosition} icon={customMarker}>
        <Popup>{popUpText}</Popup>
      </Marker>
    </ReactLeafletMap>
  );
};

export default Map;
