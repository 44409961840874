import React, { ReactElement } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../layouts/layout';
import { Apartment, Locale } from '../interfaces/common';
import SEO from '../components/common/SEO';
import Map from '../components/apartment-details/Map';
import ImageGallery from '../components/apartment-details/ImageGallery';
import ApartmentHighlightsCard from '../components/apartment-details/ApartmentHighlightsCard';
import Content from '../components/common/Content';
import { fontColor, fontWeight, spacing } from '../styles';

interface ComponentProps {
  apartment: Apartment;
}

type Props = PageProps<unknown, ComponentProps>;

const StyledSubAddress = styled.h3`
  color: ${fontColor.secondary};
  font-weight: ${fontWeight.light};
  margin-top: 0;
  text-align: left;
`;

const StyledAddress = styled.h1`
  font-weight: ${fontWeight.medium};
  margin: 0;
  text-align: left;
`;

const ImageGalleryContainer = styled.div`
  padding: ${spacing['4']} 0;
`;

const ApartmentHighlightsCardContainer = styled.div`
  padding: ${spacing['4']} 0;
`;

const StyledParagraph = styled.p`
  padding: ${spacing['2']} 0;
`;

const ApartmentPage = ({ pageContext }: Props): ReactElement => {
  const intl = useIntl();
  const locale = intl.locale as Locale; // @TODO remove as Locale
  const { apartment } = pageContext;

  return (
    <Layout>
      <SEO title={apartment.address.streetAddress} />
      <Content>
        <ImageGalleryContainer>
          <ImageGallery imgList={apartment.imageIds} />
        </ImageGalleryContainer>
        <StyledAddress>{apartment.address.streetAddress}</StyledAddress>
        <StyledSubAddress>
          {apartment.address.neighbourhood} - {apartment.address.city}
        </StyledSubAddress>
        {apartment.description[locale].split('\b').map((p, index) => (
          <StyledParagraph key={index}>{p}</StyledParagraph>
        ))}

        <ApartmentHighlightsCardContainer>
          <ApartmentHighlightsCard apartment={apartment} />
        </ApartmentHighlightsCardContainer>
      </Content>
      <Map
        location={apartment.location}
        popUpText={apartment.address.streetAddress}
      />
    </Layout>
  );
};

export default ApartmentPage;
