import React, { ReactElement, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';

import {
  breakpoints,
  purple,
  purpleGradient,
  shadows,
  spacing,
} from '../../styles';
import Image from '../common/Image';
import ImageGalleryArrows from './ImageGalleryArrows';

const ImagePreviewContainer = styled.div<{ isSelected: boolean }>`
  width: 100%;
  border-top: 5px solid;
  margin: ${spacing['2']} 0;
  border-color: ${props => (props.isSelected ? purple['4'] : 'transparent')};
  border-image: ${props =>
    props.isSelected
      ? `linear-gradient(
    to right,
    ${purple['5']} 0%,
    ${purpleGradient['5']}
  )`
      : 'none'};
  border-image-slice: 1 0;

  &:hover {
    cursor: pointer;
    box-shadow: ${shadows['1']};
    border-image: linear-gradient(
      to right,
      ${purple['5']} 0%,
      ${purpleGradient['5']} 100%
    );
    border-image-slice: 1 0;
  }
`;

const SliderImgContainer = styled.div`
  & > img {
    max-height: 2rem;
  }
`;

interface Props {
  imgList: string[];
}

const StyledArrowBtnContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.sm}) {
    position: absolute;
    display: block;
    bottom: 0;
    right: 2rem;
  }
`;

const StyledSliderContainer = styled.div`
  position: relative;
`;

const StyledImagePreviewsContainer = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints.sm}) {
    display: block;
  }
`;

const ImageGallery = ({ imgList }: Props): ReactElement => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider, setSlider] = useState<Slider | null>(null);

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (_: number, nextImgIndex: number) =>
      setCurrentSlide(nextImgIndex),
  };

  if (imgList.length === 0) {
    <div />;
  }

  return (
    <>
      <StyledSliderContainer>
        <Slider ref={slider => setSlider(slider)} {...settings}>
          {imgList.map((imgName, index) => (
            <SliderImgContainer key={`slide-${index}`}>
              <Image imgName={imgName} />
            </SliderImgContainer>
          ))}
        </Slider>
        <StyledArrowBtnContainer>
          <ImageGalleryArrows
            onLeftArrowClick={() => slider && slider.slickPrev()}
            onRightArrowClick={() => slider && slider.slickNext()}
          />
        </StyledArrowBtnContainer>
      </StyledSliderContainer>
      <StyledImagePreviewsContainer>
        <Row>
          {imgList.map((imgName, index) => (
            <Col
              xs={4}
              sm={3}
              lg={2}
              key={`preview-${index}`}
              onClick={() => slider && slider.slickGoTo(index)}
            >
              <ImagePreviewContainer isSelected={currentSlide === index}>
                <Image imgName={imgName} />
              </ImagePreviewContainer>
            </Col>
          ))}
        </Row>
      </StyledImagePreviewsContainer>
    </>
  );
};

export default ImageGallery;
