import styled from 'styled-components';
import { breakpoints, white } from '../../styles';
import ArrowRightIcon from '../../images/icons/arrow-right.svg';
import ArrowLeftIcon from '../../images/icons/arrow-left.svg';

const arrowBtnSize = 4; // in rems

const StyledArrowBtn = styled.button`
  background-color: ${white};
  border: 0;
  width: ${arrowBtnSize}rem;
  height: ${arrowBtnSize}rem;

  &:hover {
    cursor: pointer;
  }
`;

const StyledIconImg = styled.img`
  width: ${arrowBtnSize - 2}rem;
  opacity: 0.6;
  margin: 0 auto;

  @media only screen and (min-width: ${breakpoints.sm}) {
    width: ${arrowBtnSize - 2}rem;
  }
`;

import React, { ReactElement } from 'react';

interface Props {
  onRightArrowClick: () => void;
  onLeftArrowClick: () => void;
}

const ImageGalleryArrows = ({
  onLeftArrowClick,
  onRightArrowClick,
}: Props): ReactElement => {
  return (
    <>
      <StyledArrowBtn onClick={onLeftArrowClick}>
        <StyledIconImg src={ArrowLeftIcon} alt="" />
      </StyledArrowBtn>
      <StyledArrowBtn onClick={onRightArrowClick}>
        <StyledIconImg src={ArrowRightIcon} alt="" />
      </StyledArrowBtn>
    </>
  );
};

export default ImageGalleryArrows;
